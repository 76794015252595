export const faqData = [
    {
        title: "What is an CNFT and what do I get?",
        content: `
        CNFT is a Cardano non fungible token (CNFT), which is a native token on the Cardano blockchain.
        Associated with each CNFT is a policy ID and metadata that is timestamped on the blockchain to show proof of ownership and authenticity.
        You will receive this token in your wallet which will include the cover art and the audio of the music which is playable from the NFT.        
        `
    },
    {
        title: "What is the value proposition of NFTS from Artist Drop?",
        content: `In a digital era, there are currently limited ways to show your fandom and support of your favorite artists. While everyone has the ability to stream music, with ArtistDrop NFTs you will have proof of ownership, the digital equivalent to owning a record.
         Now when you hear your favorite artist and you tell people 'I listened to them before they were popular' you'll have their NFT to back it up!`
    },
    {
        title: "Where should I send my ADA to receive a NFT Sold on ArtistDrop?",
        content: `Each ArtistDrop will have a Shelley era address that a specific amount of ADA should be sent to. It is extremely important that this is sent from a verified Cardano wallet
         (i.e. Daedalus, Yoroi, Nami). Funds sent from an exchange will not allow for the NFT to be sent, and you may lose your ADA.`
    },
    {
        title: "How Do I view my NFT?",
        content: `Some Wallets are better than others for viewing NFTS.
        Pool.pm, is a Cardano Blockchain explorer and is the best spot to view your NFTs. Paste your wallet address into the search bar and the NFTs in your wallet will be displayed.`
    },
    {
        title: "Will there be Artist Drops for other musicians?",
        content: `Yes! Join our Discord server to stay in the loop or get involved.
         We are always interested in elevating any qualified musician's career. Please reach out if you would like to be listed on ArtistDrop`
        
    }
]