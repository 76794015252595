import React, {useState}  from 'react'
import { Row, Col, Icon } from 'react-bootstrap'
import { container } from '../styles/style.scss'
import background from '../images/artist-drop-background.png'
import soloDrip from '../images/solo-drop-green.gif'
import { FaTwitter, FaYoutube } from 'react-icons/fa';
import cardanoLogo from '../images/cardano-ada-logo.png'


const Footer = () => {

    return (
        <div className={container} id="footer">
        <header           
        style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            paddingTop: `2rem`
        }}    
    >  
        <Row>
            <Col>
                <button
                    style={{ 
                        marginTop: `2.0875rem`,
                        textAlign: "center",
                        fontFamily: 'Montserrat',
                        color: 'white',
                        border: '3px solid orange',
                        backgroundColor: '#48CF89',
                       
                        
                    }}
                >
                   <a href="https://discord.gg/wPZPbmqxPq"
                   style={{
                    color: 'white',
                    textDecoration: 'none'
                
                }}> 
                Join The Community on Discord</a>
                </button>
                <p style={{
               
                    paddingTop: `1.0075rem`,
                    color: 'white',
                    fontFamily: 'Bungee', 
                }}
                >   © {new Date().getFullYear()}, Partnered with
                {` `}<a href="https://www.HedgesPool.com" target="_blank" style={{color: `#FFB324`}}>Hedges Stake Pool</a> </p>
                <p style={{
               color: 'white',
               fontFamily: 'Bungee', 
           }}
           >  </p>
      

            </Col>
            <Col>
                <h1 className="img-fluid"
                    style={{        
                    paddingTop: `2.0875rem`,
                    textAlign: 'center',
                    paddingLeft: `1.0875rem`,     
                    fontFamily: 'Bungee', 
                    fontSize: `3rem`,
                    color: `#FFB324`
                    }}
                    >
                        <img src={soloDrip} style={{
                            height: `6rem`
                        }}class="img-fluid" alt="Responsive image" />
                </h1>
            </Col>

            <Col style={{
                    border: '3px solid orange',
                    marginTop: `2.0875rem`,
                    marginBottom: `2.0875rem`,
                    textAlign: 'center'
                }}>
                <h2
                    style={{ 
                                          
                        textAlign: "center",
                        fontFamily: 'Montserrat',
                        color: 'white',
                        
                    // backgroundColor: '#48CF89'
                        
                    }}
                >
                    Stay Up to Date on Social Media

                </h2>
                <Row>
                <Col>
                <a
                    className="nav-link text-light navbar-link hover"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/ArtistDrop_IO"
                  >
                    <FaTwitter size="3rem"/>
                  </a>
                  </Col>
                  <Col>
                  <a
                    className="nav-link text-light navbar-link hover"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.youtube.com/channel/UCuJF4s9BgZA0bsQA6eXcmrQ"
                  >
                    <FaYoutube size="3rem"/>
                  </a>
                  </Col>
                  </Row>
                

            </Col>
        </Row>
    </header>
    </div>
    )

}

export default Footer