import React, {useState}  from 'react'
import { Row, Col } from 'react-bootstrap'
import { container } from '../styles/style.scss'
import YoutubeEmbed from "./youtubeModal";


const About = () => {

    return (
        <div className={container} id="about">
            <Row>
                <Col>
                    <h1 className="img-fluid"
                        style={{   
                            paddingTop: `2.0875rem`,
                            paddingBottom: `1.0875rem`,
                            textAlign: "center",   
                            fontFamily: 'Bungee', 
                            color: `#167BFF`
                        }}
                        >
                            Own a piece of history in an artist's career
                    </h1>
                    <YoutubeEmbed embedId="nnml9bKRjoM" />
                    {/* <YoutubeEmbed embedId="nnml9bKRjoM" /> */}

                </Col>
                {/* <Col>
                <h4 classnName="img-fluid"
                    style={{
                        paddingTop: `2.0875rem`,
                        paddingBottom: `1.0875rem`,
                        paddingRight: `2.0875rem`,  
                        textAlign: "center",   
                        fontFamily: 'Bungee', 
                        color: `#167BFF`
                    }}
                >
                   OWN A PIECE OF HISTORY IN AN ARTISTS CAREER
                </h4>
             
                <table style={{  
                    }}
                    align="center">
                    <tbody  style={{
                                    backgroundColor: `red`,                                    
                                    borderRadius: `5rem`
                                }}
                            >
                        <tr>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `red`,                                    
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    width: '5.0875rem',
                                }}
                            >
                            <img src={discoverIcon} class="img-fluid" alt="Responsive image" />
                            Discover
                            </td>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    width: '5.0875rem'
                                }}
                            >
                            <img src={collectIcon} class="img-fluid" alt="Responsive image" />Collect</td>

                        </tr>
                        <tr class="text-center">
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    width: '5.0875rem',
                                }}
                            >
                            <img src={purchaseIcon} class="img-fluid" alt="Responsive image" />Purchase</td>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    width: '5.0875rem'
                                }}
                            >
                            <img src={sellIcon} class="img-fluid" alt="Responsive image" />Sell</td>

                        </tr>
                    </tbody>
                </table>
                </Col> */}
            </Row>
            
        </div>
    )
}

export default About