import * as React from "react"
import Helmet  from 'react-helmet'
import Header from '../components/header'
import CustomNavbar from '../components/navbar'
import About from '../components/about'
import Artists from '../components/artists'
import Purchase from '../components/purchase'
import Contact from '../components/contact'
import Footer from '../components/footer'
import { container } from '../styles/style.scss'
import { faqData } from "../utils/content"
import FaqOkay from "../components/faq"

// styles
const pageStyles = {
  
  padding: 96,
  fontFamily: 'Bungee',
}
const headingStyles = {
 
  backgroundColor: "#48CF89",
}


// markup
const IndexPage = () => {
  return (
    <div className={container}>
      <Helmet>
      <title>Artist Drop</title>
        <meta
          name="For people who are true to themselves"
          content="Great art and Great people"
        />
        <meta name="keywords" content="React, Bootstrap, Gatsby, Material Design, Template" />
        <meta name="author" content="Keith Hedges" />
        <link rel="canonical" href="" />
      </Helmet>
      <CustomNavbar/>
      <Header className="backgroundHeader"/>    
      <About/>
      <Artists/>
      <Purchase/>
      <h1 className="img-fluid"
                style={{        
                paddingTop: `2.0875rem`,
                textAlign: 'center',
                paddingLeft: `1.0875rem`,     
                fontFamily: 'Bungee', 
                fontSize: `3rem`,
                color: `#FFB324`
                }}
                >
                FAQ
      </h1>
      <div className="accordion">
        {faqData.map(({ title, content }) => (
          <FaqOkay title={title} content={content} />
        ))}
      </div>
    
      <Contact/>
      <Footer/>
    </div>
  )
}

export default IndexPage
