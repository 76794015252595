import React, {useState}  from 'react'
import { Card, CardBody } from 'react-bootstrap'
import { container } from '../styles/style.scss'
import { db } from "../firebase"
import ADLogo from '../images/ADLogo.jpg'
// const admin = require('firebase-admin')
// admin.initializeApp()
// const db = admin.firestore()
// import { db } from "../firebase"

const Contact = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [loader, setLoader] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)

        db.collection('contacts').add({
            name: name,
            email: email,
            message: message,
        })
        .then(() => {
            alert('Thank you for the Message, it has been submitted!')
            setLoader(false)
        })
        .catch((error) => {
            alert(error.message)
            setLoader(false)
        })

        setName('')
        setEmail('')
        setMessage('')
    }

    return (
        <div className={container} id="contact"style={{
            padding: `2rem`,
        }}>
       
        <form className="form" onSubmit={handleSubmit}>
        <h1 className="img-fluid"
                style={{        
     
                paddingTop: `3.0875rem`,
                textAlign: 'center',    
                fontFamily: 'Bungee', 
                fontSize: `3rem`,
                color: '#48CF89',
               
                }}
                >
                    Contact Us
            </h1>
            <Card style={{
                maxWidth: '500px',
                // width: `24rem`,
                marginLeft: 'auto',
                marginRight: 'auto',
                fontFamily: 'Montserrat',
                padding: `2rem`,
                backgroundColor: '#bfbfbf'                
            }}>
                
            <p style={{textAlign: 'center'}} > 
                <img src={ADLogo} class="center" alt="Responsive image" style={{
                    borderRadius: `5rem`,
                    // marginTop: `2rem`,
                    height: 150,
                    width: 150,
                }}/>
            </p>
            <h2 className="img-fluid"
                style={{        
                    textAlign: 'center',
                    fontFamily: 'Bungee', 
                    color: '#167BFF',
                }}
                >
                    Are you an artist looking to get listed?
            </h2>
            <label>Name</label>
            <input className="form-body" placeholder="name"
                required 
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <label>Email</label>
            <input className="form-body" placeholder="email"
                required 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <label>Message</label>
            <textarea className="form-body" placeholder="Message"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <button type="submit" style={{
                background : loader ? "#ccc" : "#48CF89",
                borderRadius: `5rem`,
                width: `15rem`,
                marginLeft: 'auto',
                marginRight: 'auto',             
                }}>
                Submit
            </button>
            </Card>
        </form>
       
        </div>
        
        // <div className={container}>
        //     <Card>
          
        //     <div className="form-header">
        //     <h1 className="img-fluid"
        //         style={{        
        //         paddingTop: `2.0875rem`,
        //         textAlign: 'center',
        //         paddingLeft: `1.0875rem`,     
        //         fontFamily: 'Bungee', 
        //         fontSize: `3rem`,
        //         color: '#48CF89'
        //         }}
        //         >
        //             Contact Us
        //     </h1>
        //     <h4 classnName="img-fluid"
        //             style={{
        //                 paddingTop: `1.0875rem`,
        //                 paddingBottom: `1.0875rem`,
        //                 paddingRight: `2.0875rem`,  
        //                 textAlign: "center",   
        //                 fontFamily: 'Bungee', 
        //                 color: `#167BFF`
        //             }}
        //         >
        //            Are you an artist looking to get listed?
        //         </h4>
        //         </div>
        
        //     </Card>
        // </div>

    )

}

export default Contact