import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyCCNxKIc0zyk2OAMnbXKUtZ8B-F6yhHNiU",
    authDomain: "artist-drop-io.firebaseapp.com",
    projectId: "artist-drop-io",
    storageBucket: "artist-drop-io.appspot.com",
    messagingSenderId: "829465998300",
    appId: "1:829465998300:web:6f2adcda3ed7bb2fbcd519"
  };
  // Initialize Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();
  // const auth = firebase.auth();
  
  // export {auth};
  export {db};