import React, {useState}  from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { container } from '../styles/style.scss'
import PaymentModal from "../components/paymentModal";
import signatureGIF from '../images/03_matt_signature_v03.gif'
import flickerGIF from '../images/01_light_flicker.gif'
import axios from "redaxios";

const headingStyles ={
    color: "#232129",
    padding: 96,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const Purchase = () => {

    const [text, setText] = useState("")
    const [address, setAddress] = useState("")
    const [show, setShow] = useState(false);

    // crossdomain line might not be needed
    // { crossdomain: true}
    const getProjects = async () => {
        const dataEnd = axios.get("localhost:80/project").then(res => {
            console.log("The response " + JSON.stringify(res))
            console.log("The data is " + dataEnd)
            setText(res.data[0])
            // setAuthor(res.data.author)
        }).catch((error)=> {
            if(error.response) {
                // When response status code is out of 2xx range
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // When no response was received after request was made
                console.log(error.request)
            } else {
                // Error Message
                console.log(error.message)
            }
        })
    }

    const getAddress = async () => {
        const data = axios.get("https://infinite-taiga-27008.herokuapp.com/address").then(res => {
            console.log("Address response " + JSON.stringify(res))
            console.log("The data is " + data)
            setAddress(res.data)
        })
    }
    
        return (
            <div className={container}>
{/* 
                <Row className="justify-content-center my-3">

                <Button class="align-items-center"
                   style={{              
                        width: `15.0875rem`,
                        height: `3.0875rem`,
                        marginLeft: `1.0875rem`,
                        textAlign: "center",
                        fontFamily: 'Montserrat',
                        color: 'white',
                        border: '3px solid orange',
                        backgroundColor: '#48CF89'
                    }}
                    onClick={() => {
                        setShow(true);
                        // Switch to payment address once more entries are made into NFT Maker
                        getProjects()
                        getAddress()
                    }}
                >
                    Purchase Vacancy NFT
                </Button>
                <PaymentModal
                    show={show}
                    text={text}
                    address={address}
                    setShow={(b) => setShow(b)}
                />
            </Row> */}
        </div>
        )
    }



export default Purchase