import React, {useState}  from 'react'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap'
import { Link } from 'gatsby'
import { container } from '../styles/style.scss'
import soloPic from '../images/solopic.jpg'
import discoverIcon from '../images/discover_icon.png';
import axios from "redaxios";
import PaymentModal from "../components/paymentModal";
import CaptchaModal from "../components/captchaModal";
import ReCAPTCHA from 'react-google-recaptcha';


const Artists = () => {

    const captchaKey = process.env.GATSBY_SITE_KEY_DEV

    const [rate, setRate] = useState("")
    const [address, setAddress] = useState("")
    const [show, setShow] = useState(false);
    const [verified, isVerified] = useState(false)
    const [loader, setLoader] = useState(false)

    // How can we change the background of the disabled button and get the laoder to work
    const handleCaptchaClick = (value) => {
        console.log("entering submit")
        value.preventDefault()
        setLoader(true)
        console.log("value is ")
    }

    
    // crossdomain line might not be needed
    // { crossdomain: true}
    const getRates = async () => {
        // const dataEnd = axios.get("http://localhost:80/rates").then(res => {

        const dataEnd = axios.get("https://infinite-taiga-27008.herokuapp.com/rates").then(res => {
            console.log("The response " + JSON.stringify(res))
            console.log("The data is " + dataEnd)
            setRate(res.data)
            // setAuthor(res.data.author)
        }).catch((error)=> {
            if(error.response) {
                // When response status code is out of 2xx range
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // When no response was received after request was made
                console.log(error.request)
            } else {
                // Error Message
                console.log(error.message)
            }
        })
    }

    const getAddress = async () => {
        // const data = axios.get("http://localhost:80/address").then(res => {

        const data = axios.get("https://infinite-taiga-27008.herokuapp.com/address").then(res => {
            console.log("Address response " + JSON.stringify(res))
            console.log("The data is " + data)
            setAddress(res.data)
        }).catch((error)=> {
            if(error.response) {
                // When response status code is out of 2xx range
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // When no response was received after request was made
                console.log(error.request)
            } else {
                // Error Message
                console.log(error.message)
            }
        })
    }

    const handleCaptcha= (value) => {
        console.log("wen verified?")
        console.log('verified value:', value)
        setLoader(false)
        isVerified(true)
        console.log("things are looking good")
    }

    const openPaymentWindow = () => {
        const paymentUrl = "https://payment.nft-maker.io/?p=e356acc1b62047e4833423f8335fcb40&c=1";

        // Specify the popup width and height
        const popupWidth = 500;
        const popupHeight = 700;

        // Calculate the center of the screen
        const left = window.top.outerWidth / 2 + window.top.screenX - ( popupWidth / 2);
        const top = window.top.outerHeight / 2 + window.top.screenY - ( popupHeight / 2);

        const popup =  window.open(paymentUrl, "NFT-MAKER PRO Payment Gateway",  `popup=1, location=1, width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);

        document.body.style = "background: rgba(0, 0, 0, 0.5)";

        // Continuously check whether the popup has been closed
        const backgroundCheck = setInterval(function () {
            if(popup.closed) {
                clearInterval(backgroundCheck);

        console.log("Popup closed");

        // Remove dim background
        document.body.style = "";
        }
    },1000)
    }


    return (
        <div className={container} id="artists">
            <h1 className="img-fluid"
            style={{        
            paddingTop: `2.0875rem`,
            paddingBottom: `2.0875rem`,
            textAlign: 'center',
            paddingLeft: `1.0875rem`,     
            fontFamily: 'Bungee', 
            fontSize: `3rem`,
            color: `#48CF89`
            }}
            >
                Artists
        </h1>

        <Row>
        <Col> 
            <h2 style={{
                paddingBottom: `1.0875rem`,
                paddingTop: `1.0875rem`,
                textAlign: 'center',
                // marginLeft: `8.0875rem`,     
                fontFamily: 'Bungee', 
                color: `#FFB324`
            }}
            >
                Featured
            </h2>              
        <Card>
            <p style={{textAlign: 'center'}} > 
                <img src={soloPic} class="center" alt="Responsive image" style={{
                        borderRadius: `5rem`,
                        marginTop: `2rem`,
                        height: 100,
                        width: 100
                    }}/>
            </p>
            <h2 style={{
                textAlign: 'center',
                fontFamily: 'Bungee',
                color: '#167BFF',
             
                borderRadius: `2rem`,
                
         
            }}>
            Matt <br/> Frederecksen
            </h2>
        

            <Row className="justify-content-center my-3">

            <Button type="submit" class="align-items-center"
            style={{              
                    width: `17.0875rem`,
                    height: `5.5875rem`,
                    marginLeft: `1.0875rem`,
                    textAlign: "center",
                    fontFamily: 'Montserrat',
                    color: 'white',
                    borderRadius: `1rem`,
                    // border: '3px solid orange',
                    background : loader ? "#48CF89" : "#48CF89",
                }}
                // onClick we want the Captcha to show and once that is successful we want
                // everything else to show
                // onClick={handleSubmit}
                onClick={() => {
                    // setShow(true);
                    // getRates()
                    // getAddress()
                    openPaymentWindow()
                    // handleCaptcha()
                }}           
             disabled={!verified}             
             >
                Purchase "Vacancy" NFT         
            </Button>
            <div>
            <ReCAPTCHA class="g-recaptcha"
                style={{paddingTop: '2rem', textAlign: 'center'}}
                // Not working 
                // Referencing .env
                sitekey={captchaKey}
                sitekey="6LfB6YkdAAAAADMWiFaeTWJZiJHXdDQ2_Kv27VH1"
                onChange={handleCaptcha}
            />
            </div>
            <PaymentModal
                show={show}
                rate={rate}
                address={address}
                setShow={(b) => setShow(b)}
            />
            </Row>
            </Card>
        </Col>
        <Col>  
            <h2 style={{
                paddingBottom: `1.0875rem`,
                paddingTop: `1.0875rem`,
                textAlign: 'center',    
                fontFamily: 'Bungee', 
                color: `#FFB324`
            }}
            >
                Discover
            </h2>  
            <Card>
                <p style={{textAlign: 'center'}} > 
                    <img src={discoverIcon} class="center" alt="Responsive image" style={{
                                borderRadius: `5rem`,
                                marginTop: `2rem`,
                                height: 120,
                                width: 140,
                                backgroundColor: '#167BFF',
                        }}/>
                </p>
            <h2 style={{
                textAlign: 'center',
                fontFamily: 'Bungee',
                color: '#167BFF',
                borderRadius: `2rem`,
                paddingBottom: `6.8rem`
            }}>
            <Row className="justify-content-center my-3">                
            <Button class="align-items-center"
            style={{        
                    marginTop: `3.7rem`,      
                    width: `17.0875rem`,
                    height: `5.5875rem`,
                    marginLeft: `1.0875rem`,
                    textAlign: "center",
                    fontFamily: 'Montserrat',
                    color: 'white',
                    borderRadius: `1rem`,
                    backgroundColor: '#48CF89',
                    background : loader ? "#48CF89" : "#48CF89",
                }}
                // disabled
          
            >
                {/* <p style={{fontSize: `1rem`, marginTop: 'auto', marginBottom: 'auto'}}>Explore Full collection of NFTs Coming Soon</p> */}
                <Link to="/explorer" style={{
                        color: 'white',
                        textDecoration: 'none',
                        fontSize: `1rem`
                    
                    }}>
                        Explore Full collection of NFTs
                </Link>
               
                </Button>
                </Row>
            </h2>
           
            </Card>
      
            {/* <h4 classnName="img-fluid"
                    style={{
                        paddingTop: `2.0875rem`,
                        paddingBottom: `1.0875rem`,
                        paddingRight: `2.0875rem`,  
                        textAlign: "center",   
                        fontFamily: 'Bungee', 
                        color: `#167BFF`
                    }}
                >
                   OWN A PIECE OF HISTORY IN AN ARTISTS CAREER
                </h4> */}
             
                {/* <table style={{  
                    }}
                    align="center">
                    <tbody>
                        <tr>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,                                   
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    width: '5.0875rem',
                                }}
                            >
                            <img src={discoverIcon} class="img-fluid" alt="Responsive image" />
                            Discover
                            </td>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,                                   
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    width: '5.0875rem',
                                }}
                            >
                            <img src={purchaseIcon} class="img-fluid" alt="Responsive image" />
                            Purchase
                            </td>

                        </tr>
                        <tr>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    // width: '5.0875rem',
                                }}
                            >
                            <img src={collectIcon} class="img-fluid" alt="Responsive image" />Collect</td>
                            <td class="text-center"
                                style={{
                                    backgroundColor: `#FFB324`,
                                    color: `white`,
                                    borderRadius:  `5.0875rem`,
                                    // width: '5.0875rem'
                                }}
                            >
                            <img src={sellIcon} class="img-fluid" alt="Responsive image" />Sell</td>

                        </tr>
                    </tbody>
                </table> */}
        </Col>
      </Row>
      </div>
    )

}

export default Artists